import React from 'react';
import './About.css';
import Zoom from 'react-reveal/Zoom';

const About = () => {
  return (
    <div className='about-container' id='about'>
        <Zoom right>
      <h1 className='about-title'>About Us</h1>
      </Zoom>

      <Zoom left>
      <p className='about-p'>
      We are a group of people who love to travel. You want to travel but you don’t have anyone to travel with you? You want to discover new places but you feel worry that you won’t be able to understand new languages or what you can do there?
Then you are in the right place! We can find you travel mate who can be with you anywhere around the world.  Just contact us with your plans and we will find the perfect solution for you!

      </p>
      </Zoom>

    </div>
  )
}

export default About