import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';
import addNotification from 'react-push-notification';
// import Fade from 'react-reveal/Fade';
 

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_l2nkc8c', 'template_xt0yirx', form.current, 'TE-RxtZAVWX4JxGGD')
        .then((result) => {
            console.log(result.text);
            e.target.reset();   //to clear the form after sending
        }, (error) => {
            console.log(error.text);
        });
    };

   //TO MAKE NOTIFICATION APPEAR WHEN THE MESSAGE SENT
     const clickToNotify =() =>{
         setTimeout(()=>{                                       //i did setTime out to make the notification appear after 5 seconds when the message sent
          addNotification({ 
             title: '👦',
             message: 'Your Message sent',
             duration: 5000,
             native: true,
             onClick:()=> console.log('Push Notification'),
            })
   
          }, 3000);            
      }
  
   
    //ends here 
   

    
  
  return (
    <section id='contacts'>
 <div className="contactForm">


 
    <form ref={form} onSubmit={sendEmail}>
      {/* <label>Name</label> */}
      <h2>Send Message</h2>

      <div className="inputBox">
         <input type="text" name="user_name" required />
         <span>Full Name</span>
      </div>

      {/* <label>Email</label> */}
      <div className="inputBox">
         <input type="email" name="user_email" required className='email'/>
         <span>Email</span>
      </div>

      {/* <label>Message</label> */}
      <div className="inputBox">
         <textarea name="message" required/>
         <span>Type your Message</span>
      </div>

      <div className="inputBox">
          <input type="submit" value="Send"  className='btn' onClick={clickToNotify} />   {/* if it wont work ll remove part onclick and after */}
      </div>                                                         { /* THIS WAS MY FIRST PLAN BUT I CHANGED WITH THE 1 UP onClick={() => alert('Message Sent')} */}
       

    </form>
     
 </div>

 </section>
  )
}

export default Contact;

