 import React from 'react';
 import Main from './components/Main'
 import travel1 from './assets/travel1.jpg';
 import travel2 from './assets/travel2.jpg';
 import travel3 from './assets/travel3.jpg';
 import Slider from './components/Slider';
 import Navbar from './components/Navbar';
 import Gallery from './components/Gallery';
 import About from './components/About';
 import Contact from './components/Contact';
 import Footer from './components/Footer'

  //import Navbar from './components/Navbar';


 //this const now will be for the navbar without it, it wont work , that is why at 1st it was giving me white screen when i just added import and <Navbar />
 const navbarLinks = [{url: '#Main' , title: 'Home'},      //IN THE URL I WILL PUT THE LINKS 2 THE OTHER PAGES or parts from the page(WITH ID)
  {url: '#gallery-page', title: 'Gallery'},      //will change the name here and see the page
  {url: '#contacts', title: 'Contact'} ]           // here i can change the names as i want after title

function App() {
  return (
    <div className="App">          
         <Navbar navbarLinks={navbarLinks}/>              {/* the navbarLInks between the curly brackets is tha name we gave 2 const which includs the url can change the name as we want*/}  
        <Main imgSrc={travel1} />                                  {/* here the props in the Main  const Main = ({imgSrc})  */}
        <Slider  imgSrc={travel2} 
        title={'Remember that happiness is a way of travel'} 
        subtitle={'The most beautiful in the world is, of course, the world itself.'} />             {/* we are passing here the props which in slider i put it in const Slider =({imgSrc, title, subtitle, flipped}) */}
        
       {/* 2nd slider */}
        <Slider  imgSrc={travel3} 
        title={'We travel, some of us forever, to seek other states, other lives, other souls.'} 
        subtitle={'We live in a wonderful world that is full of beauty, charm and adventure. There is no end to the adventures we can have if only we seek them with our eyes open.'} 
        flipped ={true}   /*this is the flipped which was in if not */ 

        />
    
  
        <Gallery />

        <About />

        <Contact />

        <Footer />

       
    </div>
  );
}

export default App;
