import React, {useState} from 'react';
import {VscChromeClose} from "react-icons/vsc";
import './Gallery.css';
//import Rotate from 'react-reveal/Rotate, LightSpeed';
 import {Rotate, LightSpeed} from 'react-reveal';

 import { LazyLoadImage } from 'react-lazy-load-image-component';
 import 'react-lazy-load-image-component/src/effects/blur.css';


import gallery1 from '../assets/gallery1.jpg';
import gallery2 from '../assets/gallery2.jpg';
import gallery3 from '../assets/gallery3.jpg';
import gallery4 from '../assets/gallery4.jpg';
import gallery5 from '../assets/gallery5.jpg';
import gallery6 from '../assets/gallery6.jpg';
import gallery7 from '../assets/gallery7.jpg';
import gallery8 from '../assets/gallery8.jpg';
import gallery9 from '../assets/gallery9.jpg';
import gallery10 from '../assets/gallery10.jpg';
import gallery11 from '../assets/gallery11.jpg';
import gallery12 from '../assets/gallery12.jpg';
// import gallery13 from '../assets/gallery13.jpg';


const Gallery = () => {

   
    let data = [
        {
            id: 1,
            imgSrc: gallery1,
        },

        {
            id: 2,
            imgSrc: gallery2,
        },

        {
            id: 3,
            imgSrc: gallery3,
        },

        {
            id: 4,
            imgSrc: gallery4,
        },

        {
            id: 5,
            imgSrc: gallery5,
        },

        {
            id: 6,
            imgSrc: gallery6,
        },

        {
            id: 7,
            imgSrc: gallery7,
        },

        {
            id: 8,
            imgSrc: gallery8,
        },

        {
            id: 9,
            imgSrc: gallery9,
        },

        {
            id: 10,
            imgSrc: gallery10,
        },

        {
            id: 11,
            imgSrc: gallery11,
        },

        {
            id: 12,
            imgSrc: gallery12,
        },

        // {
        //     id: 13,
        //     imgSrc: gallery13,
        // },

    ]

    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) =>{
        setTempImgSrc(imgSrc);
        setModel(true)
    }

  return (

     <>
    {/* icon */}
       <div className={model ? 'model open' : 'model'}>
        <img src={tempImgSrc} alt="" />
        <VscChromeClose onClick={()=> setModel(false)} /> 
       </div>
    {/* icon finish */}

    
    <LightSpeed right cascade > 
    <h1 className='head-line underline'> Our Gallery</h1>
    </LightSpeed>

    {/* <Rotate cascade> */}
       
    <div className='gallery' id='gallery-page'> 
       {data.map((item, index)=>{
        return (
            <div className='pics' key={index} onClick={()=> getImg(item.imgSrc)}>  
            {/* <img src={item.imgSrc}   alt=''/> */}

            <LazyLoadImage
            effect='blur'
            src={item.imgSrc}   alt=''
            height=' '
            width= ' '
            padding='10px'
            />
             
        </div>
        )
       })}
    </div>
     
    
    {/* </Rotate> */}

    </>

    
  )
}

export default Gallery