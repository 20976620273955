import React, {useState} from 'react';     //we will add use state to make the hamburger menu 
import {FiMenu, FiX} from 'react-icons/fi';
import './Navbar.css';

//const navbarLinks = ({url: '/home', title: 'Home'});
const Navbar = ({navbarLinks}) => {

//now we will create variable for the hamburger this we will do it after we already finished the normal menu 
const [hamburger, setHamburger] = useState();
//and now we will create function 2 toggle the hamburger for the hamburger
const toggleHamburger = ()=>{
    setHamburger(!hamburger)
}

  return (
    <nav className='navbar'>
        <span className='logo'>Happy Travel</span>

          {/* now we will make if statement with ternary operator to make the icons appear 'hamburger menu and x icons' after we did up the useState and const toggle */}
           
           {hamburger ? (<FiMenu size={25} className='navbar-menu' onClick={toggleHamburger}/>) : (<FiX size={25} className='navbar-menu' onClick={toggleHamburger}/>)}
           {/* so this operator mean that if the menu not click it will show the menu icon but if it clicked it will show the X icon   */}
        <ul className={ hamburger ? 'nav-list': 'nav-list nav-list-active'}>         {/*the class list 1st was 'nav-list' but 2 make the hamburger work we will remove the classname and make ternary operator */}
            {navbarLinks.map((item)=>{                //we ll take the prop name and make it with map 2 loop throw it,& dont 4get that we ll put it inside ul then we will give the return after the map then the li  
                return( 
                
                <li className='nav-item' key={item.title}>     {/* because we are rendering list 'li' react need to have key so it will be passing uniq title*/}
                     
                     <a className='nav-link' href={item.url}>      {/* item.url the url coming from the const from navbarLinks up */}
                          {item.title}       {/* title from the name of the link we put up in the const navbarLInks */}
                     </a> 
                </li>
                )
            })}
        </ul>
    </nav>
  )
}

export default Navbar