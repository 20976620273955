import React from 'react';
 import './Slider.css';
 import { useInView } from 'react-intersection-observer';

 const Slider = ({imgSrc, title, subtitle, flipped}) => {     //those are the props

        //this part from the npm page     
        const { ref, inView} = useInView({
          /* Optional options */
          threshold: .4,      // it was 0 from the npm but i will change it as in the documentation between 0 and 1 
        });


  const renderContent = ()=> {
   if(!flipped){    {/*  so here we say if its not flipped make those down like that but else we will change the image and make it on the other side as its down*/}
    return (
        <>  
           <img src={imgSrc} alt="travel-pic" className='slider-img' />
           <div className="slider-content">
              <h1 className='slider-title'>{title}</h1>
              <p>{subtitle}</p>
           </div>
        
        </>
    )
   }else {
    return (
        <>
            {/* all what we can do here its the same as in if up but we will put the image down */}
           <div className="slider-content"> 
              <h1 className='slider-title'>{title}</h1>
              <p>{subtitle}</p>
           </div>
           <img src={imgSrc} alt="travel-pic" className='slider-img' />
        </>
    )
     
   }
  }
  
  
    return (
    
    <div className={inView ?'slider slider-zoom': 'slider'}   ref={ref}>{renderContent()}</div>    //i add the ref from the ref up 2 make the slide ' its the part which i add 2 from npm
     // and after the ref we will make ternary operator to make the slider-zoom class we just add in css work and do that we have inView in the const up 'state'
     // from the npm so we will give it 2 the ternary operator(if) AND BE CARFUL will remove the className here which slider and make it inside the ternary operator and add the new class slider-zoom too.
  )
}

export default Slider