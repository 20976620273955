// import React from 'react';
// import './Main.css'
// //import Img from '../assets/travel.jpg'

// function Main(imgSrc) {
//   return (
//     <div className='main'>
//         <img src={imgSrc} alt="pic-travel"  className='main-img'/>
//         <h1 className='main-title'>Don’t listen to what they say. Go see.</h1>
//     </div>
//   )
// }

// export default Main


import React from "react";
import './Main.css';
 

const Main = ({imgSrc}) =>{
    return (
        <>
         
        <div className="main" id="Main">
            
            <img src={imgSrc} alt="pic-travel"  className='main-img'/>
         <h1 className='main-title'>Don’t listen to what they say. Go see.</h1>
        </div>
        {/* <div className="overlay"></div> */}
        </>
    )
}



export default Main